@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html,
body,
#entry {
  min-height: 100vh;
}

.select__menu {
  z-index: 99 !important;
}
